import React from 'react'
import dynamic from 'next/dynamic'

const HeroHome = dynamic(() => import('../components').then(mod => mod.HeroHome))
const MediaText = dynamic(() => import('../components').then(mod => mod.MediaText))
const SliderText = dynamic(() => import('../components').then(mod => mod.SliderText))
const MediaGridText = dynamic(() => import('../components').then(mod => mod.MediaGridText))
const MainContainer = dynamic(() => import('../components').then(mod => mod.MainContainer))

import { isLinkEmpty, isObjectEmpty } from '../utils'
import { fetchPageData } from '../services'
import { STATIC_PAGE_WP_IDS } from '../utils/siteConfig'
import getGlobalData from '../services/cacheGlobalData'

/**
 * Fixed Page - Home
 */
const Home = ({ pageData }) => {
  const heroHomeImageA = pageData?.customPageContent?.heroImages?.[0]
  const heroHomeImageB = pageData?.customPageContent?.heroImages?.[1]

  const mediaText = pageData?.customPageContent?.aboutUsContent
  const sliderText = pageData?.customPageContent?.payingForwardContent
  const mediaGridText = pageData?.customPageContent?.findYourPlaceContent

  // fixed urls for residential/resorts
  heroHomeImageA.link.url = [{ uri: '/projects/' }]
  heroHomeImageB.link.url = [{ uri: '/team/' }]

  return (
    <MainContainer>
      {/* SECTION A - Hero Layout - 2x1  */}
      <HeroHome
        imageA={heroHomeImageA?.image}
        videoA={heroHomeImageA?.video?.mediaItemUrl}
        headingA={heroHomeImageA?.link.label}
        ctaA={heroHomeImageA?.link}
        descriptionA={heroHomeImageA?.description}
        imageB={heroHomeImageB?.image}
        videoB={heroHomeImageB?.video?.mediaItemUrl}
        headingB={heroHomeImageB?.link.label}
        ctaB={heroHomeImageB?.link}
        descriptionB={heroHomeImageB?.description}
      />

      {/* SECTION B - About Us - 2x1 */}
      <MediaText
        image={mediaText?.image}
        heading={mediaText?.title}
        subHeading={mediaText?.headline}
        description={mediaText?.description}
        cta={mediaText?.link}
        imagePosition='left'
        aspectRatio='aspect-w-3 aspect-h-5 md:aspect-w-7 md:aspect-h-12'
        hasVideoPlayback={false}
        indent='14'
      />

      {/* SECTION C - Concrete services */}
      <SliderText
        sliderImages={sliderText?.imagesSlider}
        heading={sliderText?.title}
        subHeading={sliderText?.headline}
        description={sliderText?.description}
        cta={sliderText?.link}
      />

      {/* SECTION D - Trade Partners */}
      <MediaGridText
        imageA={mediaGridText?.image1}
        heading={mediaGridText?.title}
        headingClassName='md:-mr-200'
        indent='0'
        subHeading={mediaGridText?.headline}
        description={mediaGridText?.description}
        cta={isLinkEmpty(mediaGridText?.link) ? false : mediaGridText?.link}
        imagePosition='left'
      />
    </MainContainer>
  )
}

// export default Home;
export default React.memo(Home)

export async function getStaticProps({ preview = false }) {
  const id = STATIC_PAGE_WP_IDS?.home
  const idType = 'DATABASE_ID'

  const data = await fetchPageData(id, idType, preview)

  const globalData = await getGlobalData()
  const pageData = data?.pageData || {}

  return isObjectEmpty(pageData)
    ? { notFound: true } // returns custom 404 page
    : { props: { preview, pageData, globalData } }
}
